.scrollable {
    overflow-y: auto;
    max-width: 680px;
}

.premiumPage {
    animation: 0.6s show ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100%;
    // height: 100%;
    overflow: scroll;
    z-index: 3;
    padding-top: 100px;
    // padding-bottom: 176px;
}

.titleContainer {
    display: flex;
    gap: 6px;
}

.Text {
    margin: 16px 16px 0;
}

.Image {
    width: 90px;
    height: 90px;
}

.Button {
    bottom: 5px;
    position: fixed;
    width: 96%;
    max-width: 680px;
    margin-bottom: 24px;
}

.privilegesContainer {
    width: 100%;
    margin-top: 12px;
}

.privelege {
    height: 58px;
    border-radius: 20px;
    border: 2px solid var(--orange-color);
    margin: 12px 12px 0;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background-color: var(--soft-yellow-color);
}

.footerText {
    margin: 16px 24px 0;
    padding-bottom: 176px;
}

.gradient {
    position: absolute;
    top: 0px;
    width: 100%;
    max-width: 680px;
    height: 240px;
    background: linear-gradient(
        0.39deg,
        rgba(255, 255, 255, 0) 0.34%,
        #ffe27b 99.66%
    );
    z-index: 1;
}

.background {
    top: 0px;
    position: absolute;
    width: 100%;
    max-width: 680px;
    height: 240px;
    height: 200px;
    mix-blend-mode: overlay;
    z-index: 2;
}

.back {
    top: 12px;
    left: 16px;
    position: fixed;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
}

.buttonWrapper {
    margin: 0 16px;
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
